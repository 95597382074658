main {
	min-height: 80vh;
}

h1 {
	font-size: 1.8rem;
	padding: 1rem 0;
}

h2 {
	margin: 0;
	margin-bottom: 1rem;
	padding: 0;
}

h3 {
	padding: 1rem 0;
}

.rating span {
	margin: 0.1rem;
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
	display: flex;
}
.carousel-caption {
	position: absolute;
	top: 0;
}

.carousel-caption h2 {
	color: #fff;
}

.carousel img {
	height: 300px;
	margin-left: auto;
	margin-right: auto;
}
.carousel a {
	margin: 0 auto;
}
@media (max-width: 900px) {
	.carousel-caption h2 {
		font-size: 2.5vw;
	}
}

.MultiCarousel .MultiCarousel-inner .item > div {
	overflow: hidden;
}
img {
	max-width: 100%;
}

.product .title {
	height: 40px;
}

.socialShare {
	margin-top: 10px;
}
.socialShare > label {
	margin-right: 10px;
	font-size: 13px;
	font-weight: bold;
}
.socialShare > button {
	margin-right: 5px;
}

.align-items-center {
	display: flex;
}

.rbt-aux {
	align-items: center;
	display: flex;
	bottom: 0;
	justify-content: center;
	position: absolute;
	right: 0;
	top: 0;
	width: 2rem;
}

.rbt-aux .btn-close {
	height: auto !important;
	color: #0000 !important;
}

.rbt-token {
	background-color: #e7f4ff;
	border: 0;
	border-radius: 0.25rem;
	color: #007bff;
	display: inline-flex;
	line-height: 1rem;
	margin: 1px 3px 2px 0;
}

.rbt-token .rbt-token-label:not(:last-child) {
	padding-right: 0.25rem;
}

.rbt-token .rbt-token-label {
	padding: 0.25rem 0.5rem;
}

.rbt-token .rbt-token-remove-button {
	background-image: none;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	box-shadow: none;
	color: inherit;
	display: flex;
	justify-content: center;
	font-size: inherit;
	font-weight: normal;
	opacity: 1;
	outline: none;
	padding: 0.25rem 0.5rem;
	padding-left: 0;
	text-shadow: none;
}

.rbt-token .rbt-token-remove-button .rbt-close-content {
	display: block;
}

.btn-primary,
.btn-primary:hover {
	background-image: linear-gradient(
		rgb(219, 48, 29),
		rgb(217, 35, 15) 6%,
		rgb(204, 33, 14)
	);
	filter: none;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(189, 30, 13);
	border-image: initial;
}

.list-group-item:last-child {
	border-bottom-right-radius: inherit;
	border-bottom-left-radius: inherit;
}

.list-group-item:first-child {
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
}

.list-group-item {
	position: relative;
	display: block;
	color: rgb(33, 37, 41);
	background-color: rgb(255, 255, 255);
	padding: 0.5rem 1rem;
	text-decoration: none;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(238, 238, 238);
	border-image: initial;
}

a {
	color: rgb(217, 35, 15);
	text-decoration: underline;
}

.page-link {
	position: relative;
	display: block;
	color: rgb(217, 35, 15);
	background-color: rgb(255, 255, 255);
	text-decoration: none;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(238, 238, 238);
	border-image: initial;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.page-item.active .page-link {
	z-index: 3;
	color: rgb(255, 255, 255);
	background-color: rgb(217, 35, 15);
	border-color: rgb(217, 35, 15);
}

.page-link:hover {
	z-index: 2;
	color: rgb(255, 255, 255);
	background-color: rgb(217, 35, 15);
	border-color: rgb(217, 35, 15);
}

.page-link:focus {
	z-index: 3;
	color: rgb(174, 28, 12);
	background-color: rgb(221, 221, 221);
	box-shadow: rgba(217, 35, 15, 0.25) 0px 0px 0px 0.25rem;
	outline: 0px;
}

a:-webkit-any-link:focus-visible {
	outline-offset: 1px;
}

.nav-link {
	display: block;
	color: rgb(217, 35, 15);
	padding: 0.9rem 1rem;
	text-decoration: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out;
}

.form-check-input:checked {
	background-color: rgb(217, 35, 15);
	border-color: rgb(217, 35, 15);
}

.alert-danger {
	color: rgb(93, 43, 95);
	background-color: rgb(235, 218, 236);
	border-color: rgb(225, 200, 226);
}

.navbar-light .navbar-nav .nav-link {
	color: rgba(0, 0, 0, 0.55);
}

/* Ensure the container takes full height and centers content */
.registration-page html,
.registration-page body {
	height: 100%;
	margin: 0;
	padding: 0;
	overflow: auto; /* Allow scrolling */
}

.registration-container {
	min-height: 70vh; /* Ensure the container takes full height */
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px; /* Add padding for better spacing */
}

/* Custom styles to ensure button colors */
.registerchoice.btn-primary {
	background-color: #007bff !important;
	border-color: #007bff !important;
	background-image: none !important;
}

.registerchoice.btn-success {
	background-color: #28a745 !important;
	border-color: #28a745 !important;
	background-image: none !important;
}

.registerchoice.btn-warning {
	background-color: #ffc107 !important;
	border-color: #ffc107 !important;
	background-image: none !important;
}

/* Additional styles for the header */
.registration-page h1 {
	font-size: 3rem;
	font-weight: bold;
	margin-bottom: 2rem;
	color: #333;
	text-transform: uppercase;
	letter-spacing: 2px;
}

/* Card styling */
.registration-page .card {
	transition: transform 0.3s ease;
}

.registration-page .card:hover {
	transform: translateY(-10px);
}

/* Ensure the page is scrollable on mobile */
@media (max-width: 768px) {
	.registration-container {
		height: auto;
		padding: 20px;
	}

	.registration-page {
		overflow-y: auto;
	}
}

/* Custom CSS for Menu Component */
.menu-dropdown {
	position: relative;
	display: inline-block; /* Ensure items are inline */
}

.menu-dropdown .dropdown-menu {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	float: left;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for dropdown */
}

.menu-dropdown .dropdown-menu.show {
	display: block;
}

.menu-dropdown .dropdown-toggle::after {
	display: none;
}

.nav.justify-content-center {
	display: flex;
	flex-wrap: nowrap; /* Prevent wrapping */
	justify-content: center; /* Center the menu items */
	width: 100%; /* Ensure it takes full width */
	padding: 0 10px; /* Add padding to the sides */
}

/* Add margin to header menu to create space between header menu and banner */
/* Adjust spacing between header and menu items */
.header-menu {
	margin-bottom: 20px; /* Adjust the value as needed */
	border-bottom: 1px solid rgb(217, 35, 15); /* Subtle bottom border */
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
	padding: 10px 0; /* Add padding for better spacing */
	background-color: white; /* Ensure the background is white */
	display: flex;
	justify-content: center; /* Center the menu items */
	width: 100%; /* Ensure it takes full width */
}

/* Add styles for link colors specific to header menu */
.header-menu .navbar-nav .nav-link {
	color: black; /* Use black for the link color */
	padding: 10px 15px; /* Add padding for better spacing */
	border: 2px solid transparent; /* Initial border to maintain size */
	border-radius: 5px; /* Optional: Add border radius for rounded corners */
	transition: background-color 0.3s ease; /* Smooth transition */
}

.header-menu .navbar-nav .nav-link:hover {
	color: rgb(217, 35, 15); /* Use red for the hover color */
	background-color: rgba(
		217,
		35,
		15,
		0.05
	); /* Subtle background color on hover */
}

.header-menu .navbar-nav .dropdown-menu .dropdown-item {
	color: black; /* Use black for the dropdown item color */
	padding: 10px 15px; /* Add padding for better spacing */
	border: 2px solid transparent; /* Initial border to maintain size */
	border-radius: 5px; /* Optional: Add border radius for rounded corners */
	transition: background-color 0.3s ease; /* Smooth transition */
}

.header-menu .navbar-nav .dropdown-menu .dropdown-item:hover {
	color: rgb(217, 35, 15); /* Use red for the hover color */
	background-color: rgba(
		217,
		35,
		15,
		0.05
	); /* Subtle background color on hover */
}

/* Ensure the dropdown menu is responsive */
.menu-dropdown .dropdown-menu {
	position: static; /* Static position for dropdowns */
	width: 100%; /* Full width for dropdowns */
}

/* Add media queries for responsiveness */
@media (max-width: 768px) {
	.nav.justify-content-center {
		flex-direction: column; /* Stack items vertically */
		align-items: center; /* Center items */
	}

	.menu-dropdown {
		width: 100%; /* Full width for each item */
	}
}

/* Styles for product cards */
.product-card {
	display: flex;
	flex-direction: column;
	justify-content: space-between; /* Ensure content is spaced evenly */
	margin: 0 15px 30px 15px; /* Add horizontal and vertical spacing */
}

.product-card .card-body {
	flex-grow: 1; /* Allow card body to grow and fill available space */
	display: flex;
	flex-direction: column;
	justify-content: space-between; /* Ensure content is spaced evenly */
}

.product-card .card-img-top {
	height: 300px; /* Increase the height to 300px */
	object-fit: cover; /* Ensure the image covers the area without distortion */
}

.product-card .card-title {
	font-size: 1.25rem; /* Adjust the font size as needed */
	margin-bottom: 0.5rem; /* Add some space below the title */
}

.product-card .card-text {
	flex-grow: 1; /* Allow card text to grow and fill available space */
	margin-bottom: 1rem; /* Add some space below the text */
}

.product-card .btn {
	align-self: flex-end; /* Align the button to the bottom */
}

.breadcrumb-item + .breadcrumb-item::before {
	content: '>';
	color: #6c757d; /* Adjust the color as needed */
}

.price-container {
	display: flex;
	align-items: center;
}

.price-container .text-muted {
	margin-right: 5px;
}

.price-container .text-danger {
	margin-left: 5px;
}

.cart-icon {
	position: relative;
	display: flex;
	align-items: center;
	color: white;
}

.cart-icon .fas.fa-shopping-cart {
	font-size: 1.5rem; /* Adjust the size of the cart icon */
	position: relative;
}

.cart-text {
	margin-left: 5px;
	font-size: 1rem; /* Adjust the size of the cart text */
}

.cart-badge {
	position: absolute;
	top: 4px; /* Adjust the position to fit inside the cart icon */
	right: 37px; /* Adjust the position to fit inside the cart icon */
	background-color: red;
	color: white;
	border-radius: 50%;
	padding: 2px 6px;
	font-size: 0.75rem; /* Adjust the font size */
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Override for two-column layout on desktop and single-column on mobile */
.product-create-form .custom-col {
	flex: 0 0 50%;
	max-width: 50%;
}

@media (max-width: 767.98px) {
	.product-create-form .custom-col {
		flex: 0 0 100%;
		max-width: 100%;
	}
}
/* Override .col-md-4 within the product-create-form when heading is Create Product or Edit Product */
.seller-product-create-page .container .col-md-4,
.seller-product-edit-page .container .col-md-4,
.register-seller-page .container .col-md-4 {
	width: 800px;
}

/* Ensure the image fits within the container without distortion */
.react-multi-carousel-item img {
	width: 100%; /* Ensure the image takes the full width of the container */
	height: auto; /* Ensure the image height adjusts automatically */
	object-fit: cover; /* Ensure the image covers the container without distortion */
	background-color: white; /* Ensure the background color is white */
}
